<template>
  <div
    class="sidebar-bg side-nav-viralhook side-nav-collapse-container"
    :class="state?.collapsed ? 'collapse-width' : 'non-collapsed-width'"
  >
    <div>
      <div class="header-section">
        <img
          v-if="state?.collapsed"
          src="../../assets/images/sideNavLogoCollapse.svg"
          alt="logo-collapse"
        />
        <img v-else src="../../assets/images/sideNavLogoNonCollapse.svg" alt="logo-collapse" />
      </div>
      <div style="width: 256px">
        <a-menu
          v-model:selectedKeys="selectedKeys"
          mode="inline"
          theme="dark"
          :inline-collapsed="state?.collapsed"
          :items="state?.items"
          @click="routeTo"
        ></a-menu>
      </div>
    </div>
    <div @click="toggleCollapsed" class="collapse-btn">
      <img v-if="state?.collapsed" src="../../assets/images/arrowRight.svg" alt="arrow-right" />
      <img v-else src="../../assets/images/arrowLeft.svg" alt="arrow-right" />
    </div>
  </div>
</template>
<script>
import { reactive, watch, h, onMounted, ref } from 'vue';
import { Menu } from 'ant-design-vue';
import { useMenuRefresh } from '../../stores/menu';
import {
  HomeOutlined,
  AimOutlined,
  DollarOutlined,
  BarChartOutlined,
  ArrowRightOutlined,
  SettingOutlined,
  BulbOutlined,
  FlagOutlined,
  BuildOutlined
} from '@ant-design/icons-vue';
const { $API } = useNuxtApp();
import { useRouter } from 'vue-router';

export default {
  name: 'Sidebar',
  setup() {
    const refresh = useMenuRefresh();
    const selectedKeys = ref([1]);
    const state = reactive({
      collapsed: false,
      items: []
    });
    const router = useRouter();
    const routeTo = ({ key }) => {
      const menuItem = findMenuItemByKey(state?.items, key);
      if (menuItem && menuItem.link) {
        router.push(menuItem.link);
      }
    };
    const findMenuItemByKey = (menuItems, key) => {
      for (const item of menuItems) {
        if (item.key === key) {
          return item;
        } else if (item.children) {
          const child = findMenuItemByKey(item.children, key);
          if (child) {
            return child;
          }
        }
      }
      return null;
    };
    const toggleCollapsed = () => {
      state.collapsed = !state?.collapsed;
    };
    const setActiveTab = (link) => {
      state.items.forEach((menu) => {
        if (menu.children?.length > 0) {
          menu.children.forEach((child) => {
            link == child.link ? setKey(child.key) : null;
          });
        } else {
          link == menu.link ? setKey(menu.key) : null;
        }
      });
    };
    const fetchMenu = async () => {
      await $API?.menus.getAll().then((x) => {
        let temp = [];
        x.data.forEach((menu) => {
          if (menu.status == 'Active') {
            let tempMenu = {
              key: menu.id,
              icon: () =>
                h(
                  menu.name == 'Discovery'
                    ? BulbOutlined
                    : menu.name == 'Influencers'
                      ? AimOutlined
                      : menu.name == 'Campaigns'
                        ? BarChartOutlined
                        : menu.name == 'Payments'
                          ? DollarOutlined
                          : menu.name == 'Products'
                            ? FlagOutlined
                            : menu.name == 'Community'
                              ? BuildOutlined
                              : menu.name == 'Settings'
                                ? SettingOutlined
                                : HomeOutlined,
                  {
                    style: {
                      position: 'relative',
                      left:
                        menu.name == 'Discovery'
                          ? '0px'
                          : menu.name == 'Payments'
                            ? '3px'
                            : menu.name == 'Settings'
                              ? '0px'
                              : menu.name == 'Products'
                                ? '1px'
                                : menu.name == 'Community'
                                  ? '-4px'
                                  : menu.name == 'Influencers'
                                    ? '-2px'
                                    : ''
                    }
                  }
                ),
              label: menu.name,
              title: menu.name,
              link: menu.url
            };
            if (menu.submenus.length == 0) {
              temp.push(tempMenu);
            } else {
              let tempSubMenu = [];
              menu.submenus.forEach((subMenu) => {
                if (subMenu.status == 'Active') {
                  tempSubMenu.push({
                    key: subMenu.id,
                    icon: () => '',
                    label: subMenu.name,
                    title: subMenu.name,
                    link: subMenu.url
                  });
                }
              });
              temp.push({
                ...tempMenu,
                children: tempSubMenu
              });
            }
          }
        });
        state.items = temp;
      });
      setActiveTab(router?.currentRoute.value.path);
    };
    onMounted(() => fetchMenu());
    const setKey = (key) => (key != selectedKeys.value ? (selectedKeys.value = [key]) : null);

    watch(
      () => ({ ...refresh }),
      (newVal, oldVal) => {
        // So that this function only runs on state update, not on mounted
        if (newVal.refresh != oldVal.refresh) {
          fetchMenu();
        }
      }
    );
    watch(
      () => router?.currentRoute.value.path,
      (newVal, oldVal) => {
        setActiveTab(newVal);
      }
    );

    return {
      Menu,
      state,
      toggleCollapsed,
      ArrowRightOutlined,
      routeTo,
      selectedKeys
    };
  }
};
</script>
<style scoped>
@import '@/assets/styles/sidebar.css';

.collapse-width {
  width: 5rem;
  transition: 0.3s linear;
}
.non-collapsed-width {
  width: 16rem;
  transition: 0.1s linear;
}
.side-nav-collapse-container {
  height: 100vh;
}
</style>
