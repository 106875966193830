<template>
  <div class="header-container">
    <div class="header-user-profile" >
      <a-dropdown :trigger="['click']">
        <a class="ant-dropdown-link user-profile-button" @click.prevent>
          <img v-if="userImage !== null" class="user-header-image" :src="userImage" alt="user-profile" />
          <a-avatar v-else :size="10">
            <template #icon>
              <UserOutlined />
            </template>
          </a-avatar>
          <span class="header-user-profile-name" >{{ userName }}</span>
        </a>
      <template #overlay>
        <a-menu  @click="handleMenuClick">
          <a-menu-item key="1">
            <div @click="openUserModal" >
              Profile
            </div>
          </a-menu-item>
          <a-menu-item key="2">
            Settings
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="3"><div @click="openUserConformation" class="logout-btn" ><i class="fas fa-arrow-left"></i> Logout</div></a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    </div>
    <a-modal v-model:open="open" title="" @ok="handleOk" width="550px">
      <div class="individual-influencer-modal-header" >
        <div>
          <span class="individual-influencer-modal-header-text" >User Profile</span>
        </div>
        <div class="individual-influencer-modal-header-icons" >
          <div @click="handleOk" >
            <CloseOutlined />
          </div>
        </div>
      </div>
      <div class="header-divider" >
        <a-divider />
      </div>
      <div class="user-profile-info" >
        <div class="account-info-header" >
          <span class="account-info-title" >Account Info</span>
        </div>
        <div class="account-info" >
          <div><p class="account-info-text" >Name</p></div>
          <div><p class="account-info-text" >{{ userName }}</p></div>
        </div>
        <div class="account-info" >
          <div><p class="account-info-text" >Email</p></div>
          <div><p class="account-info-text" >{{ userEmail }}</p></div>
        </div>
        <div class="account-info" >
          <div><p class="account-info-text" >Status</p></div>
          <div><p class="account-info-text" >{{ userStatus }}</p></div>
        </div>
        <div class="account-info" >
          <div><p class="account-info-text" >Profile Image</p></div>
          <div v-if="userImage !== null"><img :src="userImage" alt="user-profile-image" /></div>
          <div v-else >          
            <a-avatar :size="40">
              <template #icon>
                <UserOutlined />
              </template>
            </a-avatar>
          </div>
        </div>
      </div>
      <div class="footer-divider" >
          <a-divider />
      </div>
      <div class="influencer-modal-footer" >
        <div class="influencer-modal-footer-main" >
          <div class="edit-mode-footer-buttons" >
            <div class="add-new-influencer" @click="handleOk" >
              <button>Close</button>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal v-model:open="openConformation" title="" @ok="closeUserConformation" width="550px">
      <div class="user-profile-info" >
        <div class="account-info-header" >
          <span class="account-info-title" >Want to logout?</span>
        </div>
        <div><p class="account-info-text" >Are you sure you want to logout from this account?</p></div>
      </div>
      <div class="influencer-modal-footer" >
        <div class="influencer-modal-footer-main" >
          <div class="edit-mode-footer-buttons" >
              <div class="secondary-button"  @click="closeUserConformation" >
                  <button>Cancel</button>
              </div>
              <div class="add-new-influencer" @click="signOut" >
                  <button>Logout</button>
              </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
    
<script setup>
import { ref } from 'vue';
import { CloseOutlined, UserOutlined } from '@ant-design/icons-vue';
import { UserStorage } from '../../Storages/userStorage';
import { routes } from '@/enum/route.enum';
import { useRouter } from 'vue-router';

const router = useRouter();
const open = ref(false);
const openConformation = ref(false);
const userName = ref('')
const userEmail = ref('')
const userImage = ref('')
const userStatus = ref('')

const openUserModal = () => {
  open.value = true;
};

const handleOk = () => {
  open.value = false;
};

const openUserConformation = () => {
  openConformation.value = true;
};

const closeUserConformation = () => {
  openConformation.value = false;
};

const signOut = () => {
  UserStorage.deleteUser();
  UserStorage.deleteToken();
  localStorage.removeItem('sidebarState');
  router.push(routes.LOGIN);
};

onMounted(() => {
  const userData = UserStorage.getUser();
  userName.value = userData.name
  userEmail.value = userData.email
  userImage.value = userData.picture
  userStatus.value = userData.status
});

const handleMenuClick = ({ key }) => {
  if (key === '1') {
    openUserModal();
  } else if (key === '2') {
    // Handle Settings
  } else if (key === '3') {
    // Handle Logout
  }
};
</script> 
<style>
@import "@/assets/styles/influencer-management.css";
.header-user-profile{
  display: flex;
  justify-content: flex-end;
  padding: 8px 24px;
  cursor: pointer;
  border-bottom: 1px solid beige;
}

.user-profile-button{
  display: flex;
  justify-content: center;
  align-content: center;
}

.header-user-profile-name{
  margin-left: 8px;
  position: relative;
  top:1px;
}

.account-info-text{
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
}

.account-info-title{
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem;
}

.user-profile-info{
  display: flex;
  flex-direction: column;
  padding: 2% 3%;
}

.account-info-header{
  margin-bottom: 12px;
}

.account-info{
  display: flex;
  justify-content: space-between;
}

.account-info img{
  width: 75px;
  border-radius: 50%;
}

.user-header-image{
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
</style>