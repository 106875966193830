<template>
  <div class="app-container">
    <Sidebar />
    <div class="main-content">
      <main class="content-area">
        <Header/>
        <a-config-provider :theme="{ token: {colorPrimary: '#fa8c16', borderRadius: `3px`, fontFamily: 'Poppins'  } }" >
          <slot />
        </a-config-provider>
      </main>
    </div>
  </div>
</template>

<script>
import Sidebar from "~/components/shared/Sidebar.vue";
import Header from "~/components/shared/Header.vue";

export default {
  components: {
    Sidebar,
    Header
  },
};
</script>

<style scoped>
.app-container {
  height: 100vh;
  display: flex;
  overflow: hidden;
}
.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.content-area {
  flex: 1;
  overflow: auto;
  padding: 5px;
}
</style>
